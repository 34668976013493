/**
 * Maps imported yaml file data and make text messages provided by functions.
 * The returned mapped structure is a copy of the original.
 * Note: intended for test data mocking.
 *
 * Many yaml import libraries will provide an abject data structure according to:
 *   {textId: "text", ...}
 * Many of our apps would use a format like this instead:
 *   {textId: () => "text", ...}.
 * This method will transform the data to the function provided structure.
 *
 * Search code for usage and examples.
 *
 * @param yamlData (usually the entire imported yaml file)
 */
export const mapYamlMessagesToFunctionProvided = (yamlData: any): any => {
  let newObj: any = {};
  Object.entries(yamlData).forEach(([key, value]) => {
    if (typeof value === "string") {
      newObj[key] = () => value;
    } else {
      newObj[key] = mapYamlMessagesToFunctionProvided(value);
    }
  });
  return newObj;
};

/**
 * Accesses the translation structure with a "." separated id string for each level.
 * Returns the message or the message structure at the last level.
 * Used with imported and mapped yaml data (see mapYamlMessagesToFunctionProvided).
 *
 * Note: the intention with this method is to mock useMessage in the
 * "@messageformat/react" package.
 *
 * Search code for usage and examples.
 *
 * @param id property access string - e.g. "page.customAccess.title"
 * @param messageData the mapped imported yaml data structure containing all the messages
 */
export const getMockMessage = (id: string, messageData: any): any => {
  let mockMessage = messageData;
  id.split(".").forEach(id => mockMessage = mockMessage[id]);

  // Return text or the message structure containing function provided messages
  if (typeof mockMessage === "function") {
    return mockMessage(); // return the text
  }
  return mockMessage; // return message object containing message functions
};


/**
 * Mock typical use hook return with data
 */
export const asUseHookReturn = (mockData: any): any => (
  {
    data: mockData,
    isLoading: false,
    isError: false,
  }
);
